<template>
  <div class="modal" id="ResetConfigurationModal" style="display: block">
    <div class="modal-dialog center">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t("RESETTING_CURRENT_TEST") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="$emit('hideResetModal')"
          ></button>
        </div>
        <div class="modal-body">
          <p>{{ $t("RESETTING_CURRENT_TEST_BODY_TEXT") }}</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="$emit('hideResetModal')"
          >
            {{ $t("NO") }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="$emit('resetConfiguration')"
          >
            {{ $t("YES") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetConfigurationModal",
  emits: ["hideResetModal", "resetConfiguration"],
};
</script>

<style lang="scss" scoped></style>
