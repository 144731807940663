<template>
  <div class="flex custom-tooltip tooltip-middle-width">
    <button
      type="button"
      class="btn btn-primary w-100 flex text-center"
      :disabled="disabled"
      :class="{ 'btn-primary': primary, 'btn-secondary': !primary }"
      data-jp="start-on-site"
      @click="runConfig"
    >
      <span>{{ $t(text) }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "OnSiteButton",
  props: ["runConfig", "primary", "text", "disabled"],
};
</script>

<style>
.flex {
  display: flex;
}
span {
  margin: 0 auto;
}
</style>
