<template>
  <div class="modal" id="myModal" style="display: block">
    <div class="modal-dialog center">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t("DELETION_OF_TEST") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="$emit('hideDeleteModal')"
          ></button>
        </div>
        <div class="modal-body">
          <p>{{ $t("SURE_TO_DELETE_TEST") }}</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="$emit('hideDeleteModal')"
          >
            {{ $t("NO") }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="$emit('deleteConfig')"
          >
            {{ $t("YES") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteConfigurationModal",
  emits: ["hideDeleteModal", "deleteConfig"],
};
</script>

<style lang="scss" scoped></style>
